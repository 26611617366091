<template>
  <div :style="f_calculateDivStyle()">
    <!-- <div style="background: white; min-height: 750px; width: 100%;"> -->
    <template v-if="d_showModal">
      <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data" :wdmr_id="wdmr_id" :layer_id="layer_id" :p_wdmrSettings="d_wdmrSettings" :p_saveBehaviour="d_saveBehaviour" :wdmr_token="wdmr_token" :layer_data="layer_data" :p_clientVariables="d_clientVariables" :p_modelId="model_id" :p_schemaId="schema_id" :p_clientSchemaId="client_schema_id" :p_gender="d_gender"></wisdom-data-modal>
    </template>
    <template v-else>
      <b-row v-if="d_loadingText">
        <b-col cols="12" style="text-align: center;">
          {{ d_loadingText }}
        </b-col>
      </b-row>
      <template v-if="d_errorData.show">
        <b-row>
          <b-col cols="12" style="text-align: center; font-size: 100px;">
            Oops!
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: center;">
            <img src="@/icon/4035067.png" class="img-rounded img-responsive" style="width: 200px;" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: center; font-size: 40px;">
            {{ d_errorData.text }}
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import auth from '@/auth';
import WmanagerService from '@/services/wmanager';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'ClientWdmrDataRecord',
  computed: {
    ...mapGetters({})
  },
  components: {
    WisdomDataModal
  },
  data () {
    return {
      d_gender: '',
      model_id: '',
      client_schema_id: '',
      schema_id: '',
      d_standartVariablesOfQuery: ['layer_id', 'client_id', 'style', 'record_type', 'wdmr_token', 'screen_mode', 'wdmr_id', 'id', 'client_schema_id', 'schema_id', 'model_id', 'gender'],
      d_clientVariables: {},
      d_clientIdList: [],
      d_loadingText: '',
      layer_data: {},
      d_showModal: false,
      layer_id: '',
      wdmr_id: '',
      wdmr_token: '',
      d_selectedWdmrData: '',
      d_saveBehaviour: {
        'type': 'manuel',
      },
      d_errorData: {
        'text': '',
        'show': false,
      },
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmrSettings: {
        'wisdom_param': {
          'settings': 0,
          'mode': 4, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
          'user_style': 0,
          'style': {
            'caption': {
              'fontSize': 12,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            },
            'value': {
              'fontSize': 10,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            }
          }
        },
        'wdm_designer': {
          'show': 0,
          'settings': 0,
          'table': []
        },
        'right_page_mode': {
          'page': ''
        },
        'column_settings': {
          'cols': 1
        },
        'wdmr_schema': {
          'wdm_anatomy': {
            'show': 0,
            'view_mode': 'basic'
          },
          'wdm': {
            'show': 0,
            'view_mode': 'basic'
          }
        },
        'bmi_gfr_widget': {
          'show_mode': 'left_right'
        },
        'page_divide': 1,
        'settings': {
          'show': 0
        },
        'anatomy_component': {
          'mode': 2,
        },
        'anatomy_group': {
          'cols': 1
        },
        'favorite_show_mode': 0,
        'group': {
          'show': 1,
          'mode': 2
        },
        'date': {
          'show_as_row': 0
        },
        'wdmr_header_mode': 4, // 1 or 2 or 3 or 4
        'document_upload': {
          'show': 0
        }
      },
    };
  },
  created: function () {},
  mounted: function () {
    for (let k in this.$route.query) {
      if (this.d_standartVariablesOfQuery.indexOf(k) === -1) {
        this.d_clientVariables[k] = this.$route.query[k];
      }
    }

    if (this.$route.query.layer_id !== undefined && this.$route.query.layer_id !== '') {
      this.layer_id = this.$route.query.layer_id;
    } else {
      console.log('Layer ID Error-1');
      let route_data = { 'name': 'welcome' };
      this.$router.push(route_data);
    }
    if (this.$route.query.wdmr_token !== undefined && this.$route.query.wdmr_token !== '') {
      this.wdmr_token = this.$route.query.wdmr_token;
    }
    if (this.$route.query.gender !== undefined && this.$route.query.gender !== '') {
      if (['male', 'female', 'unisex'].indexOf(this.$route.query.gender) !== -1) {
        this.d_gender = this.$route.query.gender;
      }
    }
    if (this.$route.query.client_id !== undefined && this.$route.query.client_id !== '') {
      let client_id_list = this.$route.query.client_id.split(',')
      if (client_id_list.length > 0) {
        this.d_clientIdList = client_id_list;
        console.log("client_id_list ", client_id_list);
      } else {
        console.log('Client ID Error-1');
        let route_data = { 'name': 'welcome' };
        this.$router.push(route_data);
      }
    } else {
      console.log('Client ID Error-2');
      let route_data = { 'name': 'welcome' };
      this.$router.push(route_data);
    }
    if (this.$route.query.record_type !== undefined) {
      if (['auto', 'manuel'].indexOf(this.$route.query.record_type) !== -1) {
        this.d_saveBehaviour.type = this.$route.query.record_type;
      }
    }
    if (this.$route.query.screen_mode !== undefined) {
      let screen_mode = 4;
      try {
        screen_mode = parseInt(this.$route.query.screen_mode);
      } catch (err) {}
      this.d_wdmrSettings.wisdom_param.mode = screen_mode;
    }
    if (this.d_clientIdList.length > 0) {
      this.f_getWdmrData();
    }
    if (this.$route.query.model_id !== undefined) {
      this.model_id = this.$route.query.model_id;
    }
    if (this.$route.query.schema_id !== undefined) {
      this.schema_id = this.$route.query.schema_id;
    }
    if (this.$route.query.client_schema_id !== undefined) {
      this.client_schema_id = this.$route.query.client_schema_id;
    }
    if (this.$route.query.wdmr_header_mode !== undefined) {
      let wdmr_header_mode = 5;
      try {
        wdmr_header_mode = parseInt(this.$route.query.wdmr_header_mode);
      } catch (err) {}
      this.d_wdmrSettings.wdmr_header_mode = wdmr_header_mode;
    }
  },
  methods: {
    f_calculateDivStyle: function () {
      let style = '';
      if (this.d_errorData.show) {
        style = 'background: #feedd7; height:' + window.innerHeight.toString() + 'px';
      }
      return style;
    },
    f_getWdmrData: function () {
      this.d_loadingText = 'Veri modülünüz hazırlanıyor...';
      let query = 'layer_id=' + this.layer_id;
      if (this.wdmr_id) {
        query += '&wdmr_id=' + this.wdmr_id;
      } else if (this.d_clientIdList.length > 0) {
        query += '&client_id=' + this.d_clientIdList[this.d_clientIdList.length - 1];
      }
      if (this.wdmr_token) {
        query += '&wdmr_token=' + this.wdmr_token;
      }
      // console.log(query);
      WmanagerService.get_layer_wdmr_and_wdm(query)
        .then(resp => {
          if (resp.data.status_code === "3000") {
            this.d_wisdomData.wisdom_data = resp.data.wdmr;
            this.wdmr_id = resp.data.wdmr.id;
            this.d_wisdomData.data_type = this.d_wisdomData.wisdom_data.type;
            this.d_wisdomData.option_data[this.d_wisdomData.data_type] = resp.data.layer_wdm;
            if (this.layer_data.id === undefined) {
              this.f_getLayerData();
            } else {
              this.d_showModal = true;
            }
            // this.d_showModal = true;
          } else if (resp.data.status_code === "3029") {
            // console.log("auth.user.authenticated ", auth.user.authenticated);
            if (auth.user.authenticated) {
              let query = 'layer_id=' + this.layer_id;
              if (this.wdmr_id) {
                query += '&wdmr_id=' + this.wdmr_id;
              }
              if (this.wdmr_token) {
                query += '&wdmr_token=' + this.wdmr_token;
              }
              WmanagerService.layer_data_get(query)
                .then(resp => {
                  if (resp.data.status_code === "3000") {
                    this.layer_data = resp.data.layer_data;
                    if (this.layer_data.data && this.layer_data.data.general && this.layer_data.data.general.generate_wdmr_token && this.layer_data.data.general.generate_wdmr_token.val && this.layer_data.data.general.generate_wdmr_token.val.value === 'yes') {
                      this.f_layerWdmrToken();
                    } else {
                      this.f_saveLayerWdmrByClientId();
                    }
                  } else {
                    this.d_loadingText = '';
                    this.d_errorData.show = true;
                    this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
                    console.log('errorr : ', resp.data);
                  }
                });
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          } else if (resp.data.status_code === "3033") {
            // console.log("auth.user.authenticated ", auth.user.authenticated);
            if (auth.user.authenticated) {
              let query = 'layer_id=' + this.layer_id;
              if (this.wdmr_id) {
                query += '&wdmr_id=' + this.wdmr_id;
              }
              if (this.wdmr_token) {
                query += '&wdmr_token=' + this.wdmr_token;
              }
              WmanagerService.layer_data_get(query)
                .then(resp => {
                  if (resp.data.status_code === "3000") {
                    this.layer_data = resp.data.layer_data;
                    if (this.layer_data.data && this.layer_data.data.general && this.layer_data.data.general.generate_wdmr_token && this.layer_data.data.general.generate_wdmr_token.val && this.layer_data.data.general.generate_wdmr_token.val.value === 'yes') {
                      this.f_layerWdmrToken();
                    } else {
                      this.f_saveLayerWdmrByClientId();
                    }
                  } else {
                    this.d_loadingText = '';
                    this.d_errorData.show = true;
                    this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
                    console.log('errorr : ', resp.data);
                  }
                });
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          } else {
            this.d_loadingText = '';
            this.d_errorData.show = true;
            this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
            console.log('errorr : ', resp.data);
          }
        });
    },
    f_getLayerData: function () {
      let query = 'layer_id=' + this.layer_id;
      if (this.wdmr_id) {
        query += '&wdmr_id=' + this.wdmr_id;
      }
      if (this.wdmr_token) {
        query += '&wdmr_token=' + this.wdmr_token;
      }
      WmanagerService.layer_data_get(query)
        .then(resp => {
          if (resp.data.status_code === "3000") {
            this.layer_data = resp.data.layer_data;
            this.d_showModal = true;
          } else {
            this.d_loadingText = '';
            this.d_errorData.show = true;
            this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
            console.log('errorr : ', resp.data);
          }
        });
    },
    f_saveLayerWdmrByClientId: function () {
      if (this.d_clientIdList.length > 0) {
        let query = 'layer_id=' + this.layer_id;
        let list_update = 'wait';
        let data = {
          'wdmr': { 'data': {} },
          'list_update': list_update,
          'layer': this.d_clientIdList
        };
        if (this.d_clientVariables && Object.keys(this.d_clientVariables).length > 0) {
          data.client_varible_list = this.d_clientVariables;
        }
        WmanagerService.layer_wdmr_save(query, data)
          .then(resp => {
            if (resp.data.status_code === "3000") {
              this.wdmr_id = resp.data.w_id;
              this.f_updateRouteQuery({ 'wdmr_id': this.wdmr_id });
              this.f_getWdmrData();
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          });
      }
    },
    f_layerWdmrToken: function () {
      if (this.d_clientIdList.length > 0) {
        let query = 'layer_id=' + this.layer_id;
        query += '&client_id=' + this.d_clientIdList[this.d_clientIdList.length - 1];
        WmanagerService.layer_wdmr_token(query)
          .then(resp => {
            /*
              {"status_code": "3000", "save_type": "revision", "status_message": "Successfully responded", "wdmr_token": "18e0293e-5ccb-46bc-9bb1-0ab06d08564c", "w_id": "22066"}
            */
            if (resp.data.status_code === "3000") {
              this.wdmr_token = resp.data.wdmr_token;
              this.wdmr_id = resp.data.w_id;
              this.f_updateRouteQuery({ 'wdmr_id': this.wdmr_id, 'wdmr_token': this.wdmr_token });
              this.f_getWdmrData();
            } else {
              this.d_loadingText = '';
              this.d_errorData.show = true;
              this.d_errorData.text = resp.data.status_message + ' (' + resp.data.status_code + ')';
              console.log('errorr : ', resp.data);
            }
          });
      }
    },
    f_updateRouteQuery: function (update_query_args) {
      let new_route_data = {
        'query': JSON.parse(JSON.stringify(this.$route.query))
      };
      for (let i in update_query_args) {
        new_route_data.query[i] = update_query_args[i];
      }

      if (Object.keys(this.d_clientVariables).length > 0) {
        for (let i in this.d_clientVariables) {
          new_route_data.query[i] = this.d_clientVariables[i];
        }
      }

      if (JSON.stringify(new_route_data.query) !== JSON.stringify(this.$route.query)) {
        this.$router.push(new_route_data);
      }
    },
  }
};

</script>

